import getCityFromIP from '@src/containers/Session/selectors/getCityFromIP';
import getCityFromUrl from '@src/containers/Session/selectors/getCityFromUrl';
import { getCurrentLang } from '@src/containers/Session/selectors/getCurrentLang';

export const loadFiltersParams = (state) => {
	const lang = getCurrentLang(state);
	const city = getCityFromUrl(state) || getCityFromIP(state) || {};
	const { id: cityId } = city;
	return {
		list: {
			cityId: cityId || null,
		},
		entity: {
			languageId: lang.id || null,
		},
	};
};
