import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../reducers';

export const useActions = () => {
	const dispatch = useDispatch();

	const resetState = useCallback(() => {
		dispatch(actions.reset());
	}, [dispatch]);

	return {
		resetState,
	};
};

export default useActions;
