const getSessionCtx = (ctx = {}) => {
	const { query, params, locale, locales = [], resolvedUrl, req, res } = ctx;

	const { cookies } = req || {};

	return {
		query,
		params,
		locale: {
			current: locale,
			locales,
		},
		req,
		res,
		resolvedUrl,
		cookies,
	};
};

export default getSessionCtx;
