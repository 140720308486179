import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Img from '@src/components/Img';
import NLink from '@src/components/NLink';

import css from './style.module.scss';

const ErrorCode = (props) => {
	const { title, description, img, backTitle, backLink, adaptive, type } =
		props;

	return (
		<div
			className={cn(css.wrap, {
				[css[`wrap_adapt_${adaptive}`]]: !!adaptive,
				[css[`wrap_${type}`]]: !!type,
			})}
		>
			{img && (
				<div className={css.imgWrap}>
					<Img {...img} className={css.img} />
				</div>
			)}
			<div className={css.title}>{title}</div>
			<div className={css.desc}>{description}</div>
			{backTitle && (
				<div className={css.btns}>
					<NLink href={backLink}>
						<a className={cn(css.btn)}>
							<span>{backTitle}</span>
						</a>
					</NLink>
				</div>
			)}
		</div>
	);
};

ErrorCode.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	img: PropTypes.shape(Img.type.propTypes),
	backTitle: PropTypes.string,
	backLink: PropTypes.string,
	adaptive: PropTypes.oneOf(['default']),
	type: PropTypes.string,
};

ErrorCode.defaultProps = {
	title: undefined,
	description: undefined,
	img: undefined,
	backTitle: undefined,
	backLink: undefined,
	adaptive: 'default',
	type: undefined,
};

export default ErrorCode;
