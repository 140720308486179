import { createSelector } from 'reselect';
import { LANG_IDS } from '../constants';

const getLang = (state) => state.Session.locale.current;
export const getCurrentLang = createSelector(getLang, (lang) => {
	return {
		lang,
		id: LANG_IDS[lang] || 1,
	};
});
