import React from 'react';
import PropTypes from 'prop-types';
import { connectInitialProps } from '@src/store';
import getSessionCtx from '@src/libs/utils/getSessionCtx';
import PageCode from '@src/components/PageCode';
import ssrSession from '@src/containers/Session/reducers/runSSR';
import ssrFilters from '@src/containers/Filters/reducers/ssrFilters';
import getIPToCity from '@src/containers/Session/reducers/getIPToCity';
import LayoutBaseCDefault from '@src/components/LayoutBase/connectors/default';
import PopupCHeaderSearch from '@src/components/Popup/set/headerSearch';

function Error({ statusCode }) {
	return (
		<>
			<LayoutBaseCDefault>
				<PageCode statusCode={statusCode} />
			</LayoutBaseCDefault>
			<PopupCHeaderSearch />
		</>
	);
}

Error.getInitialProps = connectInitialProps((store) => async (ctx) => {
	const { res, err } = ctx;
	let statusCode;
	if (res) {
		statusCode = res.statusCode;
	} else {
		statusCode = err ? err.statusCode : 404;
	}

	const { dispatch } = store;
	await dispatch(ssrSession.action(getSessionCtx(ctx)));
	await dispatch(getIPToCity.action());
	await dispatch(ssrFilters.action());
	return { statusCode };
});

Error.propTypes = {
	statusCode: PropTypes.number,
};

Error.defaultProps = {
	statusCode: undefined,
};

export default Error;
