import React from 'react';
import PropTypes from 'prop-types';
import ErrorCode from '@src/components/ErrorCode';

import css from './style.module.scss';

const PageCode = (props) => {
	const { statusCode } = props;
	let content = null;
	switch (statusCode) {
		case 404:
			content = (
				<div className={css.error404}>
					<ErrorCode
						img={{
							src: '/img/illustrations/404.svg',
							width: 380,
							height: 440,
							forceVisibility: true,
						}}
						title={t('Страница не найдена')}
						description={t(
							'Страница была удалена или не существует вовсе',
						)}
						backTitle={t('Вернуться на главную')}
						backLink="/"
					/>
				</div>
			);
			break;
		default: {
			content = (
				<ErrorCode
					img={{
						src: '/img/illustrations/502.svg',
						width: 380,
						height: 440,
						forceVisibility: true,
					}}
					type="502"
					title={t('Скоро вернемся!')}
					description={t(
						'Сервер недоступен. Попробуйте обновить страницу позже.',
					)}
				/>
			);
		}
	}

	return <div className={css.pageWrap}>{content}</div>;
};

PageCode.propTypes = {
	statusCode: PropTypes.number,
};

PageCode.defaultProps = {
	statusCode: undefined,
};

export default PageCode;
