import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions as filterActions } from '@src/containers/Filters';
import loadFilters from './loadFilters';
import { loadFiltersParams } from '../selectors/loadFiltersParams';
import { ACTIONS_PREFIX, CAPACITY } from '../constants';
// import fetchSFU from './fetchSFU';
import fetchStrapiSFU from './fetchStrapiSFU';

const ssrFilters = createAsyncThunk(
	`${ACTIONS_PREFIX}/runSSR`,
	async (_, thunkAPI) => {
		const { dispatch, getState } = thunkAPI;

		const loadFiltersP = loadFiltersParams(getState());
		await dispatch(loadFilters.action({ params: loadFiltersP }));
		dispatch(filterActions.setCapacityValues({ capacityValues: CAPACITY }));

		// await dispatch(fetchSFU.action());
		await dispatch(fetchStrapiSFU.action());
	},
);

export default {
	action: ssrFilters,
};
