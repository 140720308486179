import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions as sessionActions } from '@src/containers/Session/reducers';
import { ACTIONS_PREFIX } from '../constants';
import getCityByDomain from './getCityByDomain';
import setSelectedCity from './setSelectedCity';
import fetchConfig from './fetchConfig';

const ssrSession = createAsyncThunk(
	`${ACTIONS_PREFIX}/runSSR`,
	async (ctx = {}, thunkAPI) => {
		const { dispatch } = thunkAPI;
		const { params = {}, query, locale, resolvedUrl, cookies, res } = ctx;

		const filteredQuery = {};

		await dispatch(fetchConfig.action());

		Object.keys(query).forEach((queryKey) => {
			if (!params[queryKey]) {
				filteredQuery[queryKey] = query[queryKey];
			}
		});

		dispatch(
			sessionActions.setRouterCtx({
				params,
				query: filteredQuery,
				resolvedUrl,
			}),
		);
		dispatch(sessionActions.setLocale(locale));

		const favourites = cookies.favourites
			? JSON.parse(cookies.favourites)
			: null;

		if (cookies.soul) {
			dispatch(sessionActions.setSoul({ soul: cookies.soul }));
		}

		if (favourites) {
			dispatch(sessionActions.setFavourites({ favourites }));
		}

		if (cookies.ab_com_methods === undefined && query.yclid !== undefined) {
			const options = [0, 1, 2];
			const abVal = options[Math.floor(Math.random() * options.length)];
			res.setHeader('Set-Cookie', [
				`ab_com_methods=${abVal}; path=/; domain=spotlyst.ru; Max-Age=31536000`,
			]);
			dispatch(sessionActions.setComMethods({ variant: abVal }));
		}
		if (cookies.ab_com_methods !== undefined) {
			dispatch(
				sessionActions.setComMethods({
					variant: cookies.ab_com_methods,
				}),
			);
		}

		const viewed = cookies.viewed ? JSON.parse(cookies.viewed) : null;

		if (viewed) {
			dispatch(sessionActions.setViewed({ viewed }));
		}

		const viewedPoints = cookies.viewedPoints
			? JSON.parse(cookies.viewedPoints)
			: null;

		if (viewedPoints) {
			dispatch(sessionActions.setViewedPoints({ viewedPoints }));
		}

		if (params?.town) {
			const { payload } = await dispatch(getCityByDomain.action());
			await dispatch(
				setSelectedCity.action({
					city: payload.cityData || null,
				}),
			);
		} else if (cookies.userCity) {
			try {
				const parsed = JSON.parse(cookies.userCity);
				await dispatch(setSelectedCity.action({ city: parsed }));
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('cookiesCity error', err);
			}
		}
	},
);

export default {
	action: ssrSession,
};
